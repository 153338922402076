<template>
  <div class="packresults">
    <no-ssr>
      <div v-if="loading" style="padding-top: 8em; color: #FFF;">
        Loading
      </div>
      <!-- eslint-disable -->
    <div v-if="result" style="text-align: center;" v-html="result" />
    <!-- eslint-enable -->
    </no-ssr>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PackResults',
  data () {
    return {
      payload: '',
      result: '',
      errors: [],
      count: '',
      loading: false
    }
  },
  computed: {},
  mounted () {
    this.pack()
    this.packLoop()
  },
  beforeDestroy () {
    // console.log(this);
    clearInterval(this.loop)
  },
  methods: {
    // loaderWidth(){
    //   this.width = this.$refs.packResults.clientWidth;
    // },
    async pack () {
      const uri = 'https://api.paccurate.io/'
      const config = {
        headers: {
          Accept: 'application/json',
          authorization: 'apikey kHyMAEyrbBfn6dBL0rJDt41OLBOXr0Fn'
        }
      }
      const data = {
        layFlat: true,
        interlock: false,
        corners: true,
        eye: { x: 1, y: 1, z: 1 },
        random: true,
        allowableOverhang: 0,
        placementStyle: 'corner',
        n: 7,
        seed: false,
        boxTypeSets: ['fedex', 'usps'],
        cohortPacking: true,
        cohortMax: 1,
        randomMaxDimension: 15,
        randomMaxWeight: 5
      }
      this.result = ''
      this.loading = true
      await axios.post(uri, data, config).then((response) => {
        // console.log(response.data);
        this.loading = false
        this.result = response.data.svgs[0]

        this.$store.commit('setPackedParams', {
          itemsPackedNum: response.data.boxes[0].box.items.length,
          itemsPackedSpeed: response.data.packTime.toFixed(2),
          itemsPackedVol: (response.data.boxes[0].box.volumeUtilization * 100).toFixed(2)
        })
      })
      const theBox = document.getElementsByClassName('box-figure')
      const firstBox = theBox[0]
      if (firstBox) {
        firstBox.className = 'box-figure packani'
      }
      const shades = ['#CDC2FF', '#A692FF', '#6745FF', '#451CFC', '#866BFF']
      document.querySelectorAll('polygon').forEach((item, index) => {
        let targetShade
        shades.forEach((shade, shadeIndex) => { if (item.getAttribute('data-item-ref-id') % shadeIndex === 0) { targetShade = shades[shadeIndex] } else { targetShade = shades[0] } })
        item.style = `fill: ${targetShade} !important`
      })
    },
    packLoop () {
      const self = this
      // console.log(self);
      let count = 0
      self.loop = setInterval(function () {
        self.pack()
        // console.log(self);
        if (count >= 20) {
          clearInterval(self.loop)
          this.$store.commit('setPlayLine', false)
        }
        count++
      }, 8000)
    }
  }
}
</script>
